import React, {useCallback} from 'react';
import {useKeycloak} from "@react-keycloak/web";
import App from "./App";
import Button from "@material-ui/core/Button";

function Entry() {
    const { keycloak, initialized } = useKeycloak();

    const logout = useCallback(() => {
        keycloak.logout();
    }, [keycloak]);

    if (!initialized) {
        return <>loading...</>
    }

    if (keycloak?.authenticated) {
        if (keycloak?.tokenParsed?.email.includes('@steppay.kr')) {
            return <App />
        } else {
            return <h1>권한 없음<Button variant={"contained"} color={"primary"} onClick={logout}>로그아웃</Button></h1>
        }
    } else {
        keycloak?.login();
    }
}

export default Entry;