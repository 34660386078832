import { makeAutoObservable, flow } from 'mobx';
import API from './API';

class Store {

    siteList = [];

    constructor() {
        makeAutoObservable(this);

        this.fetchList();
    }

    async createSite(dto) {
        const site = await API.createSite(dto);
        this.siteList.splice(0, 0, site);
    }

    async removeSite(id) {
        await API.deleteSite(id);
        const index = this.siteList.findIndex(v => v.id === id);
        if (index > -1) {
            this.siteList.splice(index, 1);
        }
    }

    async fetchList() {
        this.siteList.replace(await API.getSiteList());
    }

}

export default new Store();