import axios from 'axios';

class API {

    $instance = null;

    constructor() {

    }

    get instance() {
        if (this.$instance === null) {
            this.$instance = axios.create({
                baseURL: `${process.env.REACT_APP_API_URL}`,
            });
        }

        return this.$instance;
    }

    async createSite(dto) {
        const res = await this.instance.post('/web-sites', dto);
        return res.data;
    }

    async deleteSite(id) {
        return await this.instance.delete(`/web-sites/${id}`);
    }

    async getSiteList() {
        const res = await this.instance.get('/web-sites');
        return res.data;
    }

}

export default new API();