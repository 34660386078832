import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Container, Grid, Paper, Table, TableBody, TableContainer, TableHead} from "@material-ui/core";
import InstallationForm from "./InstallationForm";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Store from './Store';
import {useObserver} from "mobx-react-lite";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import SockJsClient from 'react-stomp';
import { DateTime } from "luxon";
import {useKeycloak} from "@react-keycloak/web";

const useStyles = makeStyles((theme) => ({
    refreshButton: {
        marginBottom: '12px',
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App() {
    const { keycloak } = useKeycloak();
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const handleDeleteModalClose = () => {
        setOpenDeleteModal(false);
    };

    const [openDetailModal, setOpenDetailModal] = React.useState(false);
    const handleDetailModalClose = () => {
        setOpenDetailModal(false);
    };
    const [generatedValue, setGeneratedValue] = useState({
        id: 0,
        userName: '',
        userPassword: '',
        masterUserName: '',
        masterPassword: '',
        domain: '',
        userEmail: '',
        dbUserName: '',
        dbPassword: '',
        dbName: '',
        plan: '',
        demo: '',
    });

    const handleShowDetailClicked = (data) => {
        setGeneratedValue(data);
        setOpenDetailModal(true);
    };

    const handleDeleteClicked = async (data) => {
        setGeneratedValue(data);
        setOpenDeleteModal(true);
    };

    const handleDeleteConfirmClicked = async () => {
        await Store.removeSite(generatedValue.id);
        setOpenDeleteModal(false);
    };

    const handleClose = (event, reason) => {
        setOpen(false);
    };

    const handleRefresh = async () => {
        await Store.fetchList();
        setOpen(true);
    };

    const StatusText = useCallback((text) => {
        switch(text) {
            case 'PENDING':
                return '대기 중'
            case 'RUNNING':
                return '처리 중';
            case 'SUCCESS':
                return '생성 완료';
            case 'FAIL':
                return '생성 실패';
            case 'TIMED_OUT':
                return '생성 시간 초과';
            default:
                return '<정의되지 않은 값>'
        }
    }, []);

    const logout = useCallback(() => {
        keycloak.logout();
    }, [keycloak]);

    return useObserver( () => (
      <>
          <SockJsClient url={process.env.REACT_APP_WS_URL} topics={['/topic/public']}
            onConnect={() => {
                console.log("connected");
            }}
            onDisconnect={() => {
                console.log("Disconnected")
            }}
            onMessage={async (msg) => {
                console.log('msg', msg);
                await Store.fetchList();
            }} />
        <Container>
          <Grid container spacing={2}>
              <Grid item xs={12}>
                  <h1>Vesta Manager</h1>
                  <Button variant={"contained"} color={"primary"} onClick={logout}>로그아웃</Button>
              </Grid>
              <Grid item xs={12}>
                  <Grid container spacing={2}>
                      <Grid item xs={6}>
                          <InstallationForm />
                      </Grid>
                      <Grid item xs={6}>
                          <Button variant="contained" color="primary" className={classes.refreshButton} onClick={handleRefresh}>
                              새로고침
                          </Button>
                          <TableContainer component={Paper}>
                              <Table>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell>도메인</TableCell>
                                          <TableCell>생성 시간</TableCell>
                                          <TableCell>상태</TableCell>
                                          <TableCell>액션</TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      { Store.siteList.map( site => (
                                          <TableRow key={site.id}>
                                              <TableCell>{site.domain}.store.steppay.kr</TableCell>
                                              <TableCell>{DateTime.fromFormat(site.createdAt, "yyyy-MM-dd HH:mm:ss", { zone: 'Asia/Seoul' }).toFormat("yyyy-MM-dd HH:mm:ss")}</TableCell>
                                              <TableCell>{StatusText(site.status)}</TableCell>
                                              <TableCell>
                                                  {site.status === 'SUCCESS' && (
                                                      <ButtonGroup color="primary" aria-label="outlined primary button group">
                                                          <Button onClick={() => handleShowDetailClicked(site)}>상세보기</Button>
                                                          <Button onClick={() => handleDeleteClicked(site)}>삭제</Button>
                                                      </ButtonGroup>
                                                  )}
                                              </TableCell>
                                          </TableRow>
                                      ))}
                                  </TableBody>
                              </Table>
                          </TableContainer>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    새로고침 완료
                </Alert>
            </Snackbar>
        </Container>
          <Dialog
            open={openDetailModal}
            onClose={handleDetailModalClose}
          >
              <DialogTitle id="alert-dialog-title">{"사이트 생성 정보"}</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      <Table className={classes.table} aria-label="simple table">
                          <TableHead>
                              <TableRow>
                                  <TableCell>이름</TableCell>
                                  <TableCell>값</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              <TableRow>
                                  <TableCell>생성된 위치</TableCell>
                                  <TableCell>{generatedValue.origin}</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell>사용자 이름</TableCell>
                                  <TableCell>{generatedValue.userName}</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell>사용자 비밀번호</TableCell>
                                  <TableCell>{generatedValue.userPassword}</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell>사용자 이메일</TableCell>
                                  <TableCell>{generatedValue.userEmail}</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell>마스터 계정</TableCell>
                                  <TableCell>{generatedValue.masterUserName}</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell>마스터 계정 비밀번호</TableCell>
                                  <TableCell>{generatedValue.masterPassword}</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell>DB 사용자 이름</TableCell>
                                  <TableCell>{generatedValue.userName}_{generatedValue.dbUserName}</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell>DB 사용자 비밀번호</TableCell>
                                  <TableCell>{generatedValue.dbPassword}</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell>DB 이름</TableCell>
                                  <TableCell>{generatedValue.userName}_{generatedValue.dbName}</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell>플랜</TableCell>
                                  <TableCell>{generatedValue.plan}</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell>데모</TableCell>
                                  <TableCell>{generatedValue.demo}</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell>도메인</TableCell>
                                  <TableCell>https://{generatedValue.domain}.store.steppay.kr</TableCell>
                              </TableRow>
                          </TableBody>
                      </Table>
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleDetailModalClose} color="primary" autoFocus>
                      확인
                  </Button>
              </DialogActions>
          </Dialog>
          <Dialog
            open={openDeleteModal}
            onClose={handleDeleteModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
              <DialogTitle id="alert-dialog-title">정말로 삭제하실건가요?</DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      선택하신 사이트를 삭제하시겠습니까? 한번 삭제하면 다시 복구할 수 없습니다.
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleDeleteModalClose} color="primary" autoFocus>
                      아니요
                  </Button>
                  <Button onClick={handleDeleteConfirmClicked} color="secondary">
                      네!
                  </Button>
              </DialogActions>
          </Dialog>
      </>
    ));
}

export default App;
