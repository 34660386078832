import React, { useState } from 'react';
import { Formik } from "formik";
import { TextField, MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as Yup from 'yup';
import randomString from 'randomstring';
import Store from './Store';
import FormHelperText from "@material-ui/core/FormHelperText";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const validationSchema = Yup.object({
    userName: Yup.string().required('필수'),
    userPassword: Yup.string().required('필수'),
    domain: Yup.string().required('필수'),
    userEmail: Yup.string().email('이메일 형식이 올바르지 않습니다').required('필수'),
    dbUserName: Yup.string().required('필수'),
    dbPassword: Yup.string().required('필수'),
    dbName: Yup.string().required('필수'),
    plan: Yup.string().required('필수'),
    demo: Yup.string().required('필수'),
})

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& div': {
            padding: '6px 0',
        }
    },
    textField: {
        marginRight: '12px',
    },
    table: {
        width: '100%',
    },
}));

function InstallationForm() {
    const classes = useStyles();

    const initialValue = {
        userName: '',
        userPassword: randomString.generate(8),
        domain: '',
        userEmail: '',
        dbUserName: '',
        dbPassword: randomString.generate(8),
        dbName: '',
        plan: 'MiniPlan',
        demo: 'sitekit_demo3',
    };

    const updateDefaultPassword = () => {
      initialValue.userPassword =  randomString.generate(8);
      initialValue.dbPassword =  randomString.generate(8);
    };

    const handleSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
        try {
            values['masterPassword'] = randomString.generate(8);

            await Store.createSite(values);

            updateDefaultPassword();
            resetForm();
        } catch(e) {
            if (e.response.status === 400) {
                setErrors({
                    domain: e.response.data.message,
                });
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
      <>
        <Formik
            enableReinitialize
            initialValues={initialValue}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>
                    <div className={classes.root}>
                        <div>
                            <TextField
                                label={"사용자 아이디"}
                                name={"userName"}
                                onChange={e => {
                                    handleChange(e);
                                    setFieldValue('dbName', randomString.generate(10 - e.target.value.length));
                                    setFieldValue('dbUserName', randomString.generate(10 - e.target.value.length));
                                }}
                                onBlur={handleBlur}
                                value={values.userName}
                                margin={"normal"}
                                className={classes.textField}
                                error={errors.userName && touched.userName && Boolean(errors.userName)}
                                helperText={errors.userName}
                                inputProps={{
                                    maxLength: 9
                                }}
                            />
                            <TextField
                                label={"사용자 비밀번호"}
                                name={"userPassword"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.userPassword}
                                margin={"normal"}
                                className={classes.textField}
                                error={errors.userPassword && touched.userPassword && Boolean(errors.userPassword)}
                                helperText={errors.userPassword}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                label={"사용자 이메일"}
                                name={"userEmail"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.userEmail}
                                margin={"normal"}
                                error={errors.userEmail && touched.userEmail && Boolean(errors.userEmail)}
                                helperText={errors.userEmail}
                            />
                        </div>
                        <div>
                            <TextField
                                label={"데이터베이스 이름"}
                                name={"dbName"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.dbName}
                                className={classes.textField}
                                error={errors.dbName && touched.dbName && Boolean(errors.dbName)}
                                helperText={errors.dbName}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                label={"데이터베이스 사용자"}
                                name={"dbUserName"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.dbUserName}
                                className={classes.textField}
                                error={errors.dbUserName && touched.dbUserName && Boolean(errors.dbUserName)}
                                helperText={errors.dbUserName}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                label={"데이터베이스 패스워드"}
                                name={"dbPassword"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.dbPassword}
                                className={classes.textField}
                                error={errors.dbPassword && touched.dbPassword && Boolean(errors.dbPassword)}
                                helperText={errors.dbPassword}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </div>
                        <div>
                            <TextField
                                label={"도메인(*.store.steppay.kr)"}
                                name={"domain"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.domain}
                                className={classes.textField}
                                error={errors.domain && touched.domain && Boolean(errors.domain)}
                                helperText={errors.domain}
                            />
                            <TextField
                                select
                                name={"plan"}
                                label="플랜"
                                value={values.plan}
                                onChange={handleChange}
                                className={classes.textField}
                                error={errors.plan && touched.plan && Boolean(errors.plan)}
                                helperText={errors.plan}
                            >
                                <MenuItem value={"InfinitePlan"}>
                                    InfinitePlan
                                </MenuItem>
                                <MenuItem value={"MiniPlan"}>
                                    MiniPlan
                                </MenuItem>
                                <MenuItem value={"ProPlan"}>
                                    ProPlan
                                </MenuItem>
                                <MenuItem value={"MaxPlan"}>
                                    MaxPlan
                                </MenuItem>
                            </TextField>
                            <TextField
                                select
                                name={"demo"}
                                label="데모"
                                value={values.demo}
                                onChange={handleChange}
                            >
                                <MenuItem value={"demo1"}>
                                    demo1
                                </MenuItem>
                                <MenuItem value={"demo2"}>
                                    demo2
                                </MenuItem>
                                <MenuItem value={"demo3"}>
                                    demo3
                                </MenuItem>
                                <MenuItem value={"demo4"}>
                                    demo4
                                </MenuItem>
                                <MenuItem value={"demo5"}>
                                    demo5
                                </MenuItem>
                                <MenuItem value={"demo6"}>
                                    demo6
                                </MenuItem>
                                <MenuItem value={"demo7"}>
                                    demo7
                                </MenuItem>
                                <MenuItem value={"demo8"}>
                                    demo8
                                </MenuItem>
                                <MenuItem value={"demo9"}>
                                    demo9
                                </MenuItem>
                                <MenuItem value={"demo10"}>
                                    demo10
                                </MenuItem>
                                <MenuItem value={"demo11"}>
                                    demo11
                                </MenuItem>
                                <MenuItem value={"demo12"}>
                                    demo12
                                </MenuItem>
                                <MenuItem value={"demo13"}>
                                    demo13
                                </MenuItem>
                                <MenuItem value={"demo14"}>
                                    demo14
                                </MenuItem>
                                <MenuItem value={"demo15"}>
                                    demo15
                                </MenuItem>
                            </TextField>
                        </div>
                        <div>
                            <Button variant="contained" color="primary" type={"submit"} disabled={isSubmitting}>
                                생성하기
                            </Button>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
      </>
    )
}

export default React.memo(InstallationForm);
